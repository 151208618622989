import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {PopupboxContainer, PopupboxManager} from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import {GlobalStylePopupbox} from "../../styles/GlobalStylesPopupbox";
import {
  ContDetalles,
  ContDetallesNumeros,
  ContItemDetalles,
  ContNombreCasa,
  ItemDetalles,
  ItemDetallesIco,
  ContDetallesNumerosTitulo,
  ContDetallesNumerosValor,
  GlobalStylesItemTabsCasas,
  ContImgPlantasDeCasa,
  ImgPlantasDeCasa,
  ContTextoPlantas,
  ContPlantasCasa,
  BtnLightbox, ContBtnsLightbox, ImgPlantas, ContainerItemCasa,
} from './stylesItemTabsCasas';
import check from '../../images/oxean/check.svg';
import imgCasa1 from '../../images/oxean/casas/casa-tropica-1.png';
import imgCasa2 from '../../images/oxean/casas/casa-tropica-2.png';
import imgPlano1 from '../../images/oxean/casas/casa-tropica-plano-1.png';
import imgPlano2 from '../../images/oxean/casas/casa-tropica-plano-2.png';
import imgUbicacion from '../../images/oxean/casas/ubicacion-en-torre-tropica.svg';
// import fileFachadaPrincipal from '../../images/punta-mar/casa-c-fachada-principal.jpg';
// import fileFachadaPosterior from '../../images/punta-mar/casa-c-fachada-posterior.jpg';
// import fileSala from '../../images/punta-mar/casa-c-sala.jpg';
// import fileRecamara from '../../images/punta-mar/casa-c-recamara-principal.jpg';
// import fileRecamara2 from '../../images/punta-mar/casa-c-recamara-dos.jpg';
// import fileBanio from '../../images/punta-mar/casa-c-banio-principal.jpg';

const nameCasa = 'Tropica';

const ItemTabsCasaTropica = ({data}) => {
  const fnShowPlantas = (val) => {
    switch(val) {
      case 0:
        document.getElementById(`contCasa${nameCasa}-1`).classList.remove('display-no');
        document.getElementById(`contCasa${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnCasa${nameCasa}-1`).classList.add('negrita');
        document.getElementById(`btnCasa${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.remove('negrita');
        break;
      case 1:
        document.getElementById(`contCasa${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contCasa${nameCasa}-2`).classList.remove('display-no');
        document.getElementById(`contPlano${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnCasa${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnCasa${nameCasa}-2`).classList.add('negrita');
        document.getElementById(`btnPlano${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.remove('negrita');
        break;
      case 2:
        document.getElementById(`contCasa${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contCasa${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-1`).classList.remove('display-no');
        document.getElementById(`contPlano${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnCasa${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnCasa${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-1`).classList.add('negrita');
        document.getElementById(`btnPlano${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.remove('negrita');
        break;
      case 3:
        document.getElementById(`contCasa${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contCasa${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-2`).classList.remove('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnCasa${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnCasa${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-2`).classList.add('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.remove('negrita');
        break;
      case 4:
        document.getElementById(`contCasa${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contCasa${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-1`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}-2`).classList.add('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.remove('display-no');

        document.getElementById(`btnCasa${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnCasa${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-1`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}-2`).classList.remove('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.add('negrita');
        break;
    }
  }

  const openPopupbox = (title, file, css) => {
    const content = <img className={css} src={file} />;
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: title
        },
        fadeIn: true,
        fadeInSpeed: 500
      }
    })
  }

  return (
    <Container isFluid={true} className={' margin-bottom-plantas'}>
      <GlobalStylePopupbox/>
      <GlobalStylesItemTabsCasas/>
      <Columns className={'columns-margin-side-no'}>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>

          <ContainerItemCasa isFluid={false}>
            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <ContNombreCasa>
                  Trópica
                </ContNombreCasa>
              </Column>
            </Columns>

            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>3 recámaras</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Cocina</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Sala-comedor (doble altura)</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Sala de tv</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>3.5 baños</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Área de lavandería</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Doble terraza</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
            </Columns>

            <Columns
              className={'columns-margin-side-no display-flex cont-details-numbers mgt-details-numbers'}
              isMultiline={true}
            >
              <Column className={'global-columns-paddign-no no-display-mobile'} isSize={{mobile: 12, tablet: 6, desktop: 4, widescreen: 4}}>
                &nbsp;
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 4, widescreen: 4}}>
                <ContDetallesNumeros>
                  <ContDetallesNumerosTitulo>
                    TOTAL:
                  </ContDetallesNumerosTitulo>
                  <ContDetallesNumerosValor>
                    121.64 m2
                  </ContDetallesNumerosValor>
                </ContDetallesNumeros>
              </Column>
              <Column className={'global-columns-paddign-no no-display-mobile'} isSize={{mobile: 12, tablet: 6, desktop: 4, widescreen: 4}}>
                &nbsp;
              </Column>
            </Columns>
          </ContainerItemCasa>
        </Column>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>
          <ContPlantasCasa>
            <ContImgPlantasDeCasa id={`contCasa${nameCasa}-1`}>
              <ImgPlantasDeCasa src={imgCasa1} alt={''} />
              {/*<ContBtnsLightbox>
                <BtnLightbox id={'casa-c-fachada-principal'} onClick={() => openPopupbox('Fachada Principal', fileFachadaPrincipal, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-fachada-posterior'} onClick={() => openPopupbox('Fachada Posterior', fileFachadaPosterior, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-sala'} onClick={() => openPopupbox('Sala', fileSala, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>*/}
            </ContImgPlantasDeCasa>
            <ContImgPlantasDeCasa id={`contCasa${nameCasa}-2`} className={'display-no'}>
              <ImgPlantasDeCasa src={imgCasa2} alt={''} />
              {/*<ContBtnsLightbox>
                <BtnLightbox id={'casa-c-fachada-principal'} onClick={() => openPopupbox('Fachada Principal', fileFachadaPrincipal, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-fachada-posterior'} onClick={() => openPopupbox('Fachada Posterior', fileFachadaPosterior, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-sala'} onClick={() => openPopupbox('Sala', fileSala, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>*/}
            </ContImgPlantasDeCasa>

            <ContImgPlantasDeCasa id={`contPlano${nameCasa}-1`} className={'display-no'}>
              <ImgPlantasDeCasa src={imgPlano1} alt={''} />
              {/*<ContBtnsLightbox>
                <BtnLightbox id={'casa-c-recamara'} onClick={() => openPopupbox('Recamara Principal', fileRecamara, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-recamara-2'} onClick={() => openPopupbox('Recamara Dos', fileRecamara2, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-banio'} onClick={() => openPopupbox('Baño Principal', fileBanio, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>*/}
            </ContImgPlantasDeCasa>
            <ContImgPlantasDeCasa id={`contPlano${nameCasa}-2`} className={'display-no'}>
              <ImgPlantasDeCasa src={imgPlano2} alt={''} />
              {/*<ContBtnsLightbox>
                <BtnLightbox id={'casa-c-recamara'} onClick={() => openPopupbox('Recamara Principal', fileRecamara, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-recamara-2'} onClick={() => openPopupbox('Recamara Dos', fileRecamara2, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-banio'} onClick={() => openPopupbox('Baño Principal', fileBanio, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>*/}
            </ContImgPlantasDeCasa>

            <ContImgPlantasDeCasa id={`contUbicacion${nameCasa}`} className={'display-no'}>
              <ImgPlantasDeCasa src={imgUbicacion} alt={''} />
            </ContImgPlantasDeCasa>

            <ContTextoPlantas>
              <ImgPlantas id={`btnCasa${nameCasa}-1`} onClick={() => fnShowPlantas(0)} src={imgCasa1} />
              <ImgPlantas id={`btnCasa${nameCasa}-2`} onClick={() => fnShowPlantas(1)} src={imgCasa2} />
              <ImgPlantas id={`btnPlano${nameCasa}-1`} onClick={() => fnShowPlantas(2)} src={imgPlano1}/>
              <ImgPlantas id={`btnPlano${nameCasa}-2`} onClick={() => fnShowPlantas(3)} src={imgPlano2}/>
              <ImgPlantas id={`btnUbicacion${nameCasa}`} onClick={() => fnShowPlantas(4)} src={imgUbicacion}/>
            </ContTextoPlantas>
          </ContPlantasCasa>
        </Column>

      </Columns>

      <PopupboxContainer />

    </Container>
  )
}

export default ItemTabsCasaTropica
