import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import imgAmenitie01 from '../../images/oxean/amenities/ludoteca.svg';
import imgAmenitie02 from '../../images/oxean/amenities/hamacas.svg';
import imgAmenitie03 from '../../images/oxean/amenities/lobby.svg';
import imgAmenitie04 from '../../images/oxean/amenities/camastros.svg';
import imgAmenitie05 from '../../images/oxean/amenities/terraza.svg';
import imgAmenitie06 from '../../images/oxean/amenities/jacuzzi.svg';
import imgAmenitie07 from '../../images/oxean/amenities/gimnasio.svg';
import imgAmenitie08 from '../../images/oxean/amenities/bar.svg';
import imgAmenitie09 from '../../images/oxean/amenities/alberca.svg';
import imgAmenitie10 from '../../images/oxean/amenities/playa.svg';

export const Amenities = () => {

  return (
    <Container>
      <Columns className={'columns-margin-side-no full-height display-flex'} isMultiline={true}>
        <Column
          className={'global-columns-paddign-no no-display-mobile'}
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
          &nbsp;
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie01} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie02} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie03} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie04} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie05} />
        </Column>
        <Column
          className={'global-columns-paddign-no no-display-mobile'}
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
          &nbsp;
        </Column>
        <Column
          className={'global-columns-paddign-no no-display-mobile'}
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
          &nbsp;
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie06} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie07} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie08} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie09} />
        </Column>
        <Column
          className={'global-columns-paddign-no flex-center-important'}
          isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <img src={imgAmenitie10} />
        </Column>
        <Column
          className={'global-columns-paddign-no no-display-mobile'}
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
          &nbsp;
        </Column>
      </Columns>
    </Container>
  );
};
