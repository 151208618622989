import React, {useState} from 'react';
import {Container, Columns, Column} from 'bloomer';
import {
  Cont80Deptos,
  ContBetas, ContBloquePreventa, ContDetailsDeptos, ContDetallesCasas,
  ContImgLogoBlack, ContImgOxean1, ContImgPortada, ContListDeptos, ContPreventaTextos, ContTextFracc,
  ContTextoInfo,
  ContTextoProximamente, DetailsDeptosItem,
  GlobalStyleOxean,
  ImgLogoBlack, ImgOxean1,
  ImgPortada, Texto80, TextoDeptos, TextoPreventa, TextosOxean, UlListDeptos,
  SectionTitle, ContGallery, ContStartGallery, ImgStartGallery, ContAmenities, ContForm,
} from './styles';
import {GlobalColors, GlobalStyle} from '../../styles/GlobalStyles';
import imgPortada from '../../images/oxean/fachada.jpg';
import imgPortadaMobile from '../../images/oxean/fachada-mobile.jpg';
import imgLogoBlack from '../../images/oxean/logotipo-oxean-black.svg';
import imgOxean1 from '../../images/oxean/oxean-1.jpg';
import imgOxean2 from '../../images/oxean/oxean-2.jpg';
import TabsCasas from './tabsCasas';
import Carousel, {consts} from 'react-elastic-carousel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import ModalGuzbarraf from '../UI/Modal';
import {GlobalStyleModal} from '../UI/Modal/styles';
import imgGallery01 from '../../images/oxean/gallery/gallery-01.jpg';
import {Amenities} from './Amenities';
import ContactoComp from '../Contacto';

export const Oxean = (props) => {
  const [showModalGalleryOxean, setShowModalGalleryOxean] = useState(false);

  const myArrowGalleryOxean = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV
      ? <FontAwesomeIcon icon={faChevronLeft} color={GlobalColors.colorMenuPrimary} />
      : <FontAwesomeIcon icon={faChevronRight} color={GlobalColors.colorMenuPrimary} />;

    const side = type === consts.PREV ? 'l' : 'r';

    return (
      <div className={`cont-slider-arrow-carousel-in-modal-${side}`}>
        <button onClick={onClick} disabled={isEdge} className={'button-arrow-carousel-in-modal'}>
          {pointer}
        </button>
      </div>
    )
  }

  const onShowModalGalleryOxean = () => {
    setShowModalGalleryOxean(true);
  }
  const onHideModalGalleryOxean = () => {
    setShowModalGalleryOxean(false);
  }

  return (
    <Container className={'back-oxean'} isFluid={true}>
      <GlobalStyle/>
      <GlobalStyleOxean/>
      <div>
        <Columns className={'columns-margin-side-no full-height display-flex'} isMultiline={true}>
          <Column className={'global-columns-paddign-no'}
                  isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            <ContImgPortada>
              <ImgPortada src={imgPortada}/>
            </ContImgPortada>
          </Column>
        </Columns>
      </div>

      <ContBetas>
        <Container>
          <Columns className={'columns-margin-side-no full-height display-flex'} isMultiline={true}>
            <Column className={'global-columns-paddign-no'}
                    isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
              <ContImgLogoBlack>
                <ImgLogoBlack src={imgLogoBlack}/>
              </ContImgLogoBlack>
              <ContTextFracc>Nuevo hábito costero</ContTextFracc>
            </Column>
          </Columns>
          <Columns className={'columns-margin-side-no full-height display-flex'} isMultiline={true}>
            <Column className={'global-columns-paddign-no'}
                    isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
              <ContImgOxean1>
                <ImgOxean1 src={imgOxean1}/>
              </ContImgOxean1>
            </Column>
            <Column className={'global-columns-paddign-no'}
                    isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
              <ContBloquePreventa>
                <TextoPreventa>PREVENTA</TextoPreventa>
                <Cont80Deptos>
                  <Texto80>80</Texto80>
                  <TextoDeptos>DEPARTAMENTOS<br/> DE LUJO</TextoDeptos>
                </Cont80Deptos>
                <ContListDeptos>
                  <UlListDeptos>
                    <li>80 departamentos con vista al mar</li>
                    <li>Acceso directo a playa</li>
                    <li>Funcionalidad de 1 - 3 recámaras con terraza</li>
                    <li>Metrajes de 108 - 261 m2</li>
                    <li>Precios desde 4.8 MDP</li>
                    <li>Alberca panorámica con atractiva zona de bar</li>
                    <li>Amplio estacionamiento subterráneo</li>
                    <li>Acceso controlado y sistema de seguridad CCTV</li>
                  </UlListDeptos>
                </ContListDeptos>
              </ContBloquePreventa>
            </Column>
          </Columns>
          <Columns className={'columns-margin-side-no full-height display-flex mobile-column-row-reverse'} isMultiline={true}>
            <Column className={'global-columns-paddign-no'}
                    isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
              <ContPreventaTextos>
                <TextosOxean>
                  <b>Oxean</b> es un entorno de descanso frente al mar; un entorno que te ofrece tu propio oasis de vida para compartir con los que más amas. Estas son cada una de las opciones que han sido diseñadas para otorgar la mejor experiencia de vida en el mejor ambiente natural:
                </TextosOxean>
                <ContDetailsDeptos>
                  <DetailsDeptosItem>
                    <div>Marea</div>
                    <div>---</div>
                    <div>108.74 m2</div>
                  </DetailsDeptosItem>
                  <DetailsDeptosItem>
                    <div>Brisa</div>
                    <div>---</div>
                    <div>121.64 m2</div>
                  </DetailsDeptosItem>
                  <DetailsDeptosItem>
                    <div>Coral</div>
                    <div>---</div>
                    <div>148.02 m2</div>
                  </DetailsDeptosItem>
                  <DetailsDeptosItem>
                    <div>Trópica</div>
                    <div>---</div>
                    <div>260.36 m2</div>
                  </DetailsDeptosItem>
                  <DetailsDeptosItem>
                    <div>Paradisa</div>
                    <div>---</div>
                    <div>226.13 m2</div>
                  </DetailsDeptosItem>
                </ContDetailsDeptos>
              </ContPreventaTextos>
            </Column>
            <Column className={'global-columns-paddign-no'}
                    isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
              <ContImgOxean1>
                <ImgOxean1 src={imgOxean2}/>
              </ContImgOxean1>
            </Column>
          </Columns>
        </Container>
      </ContBetas>

      <ContDetallesCasas>
        <Container>
          <TabsCasas/>
        </Container>
      </ContDetallesCasas>

      <ContGallery>
        <Container>
          <SectionTitle><span>NUEVO HABITO</span> COSTERO</SectionTitle>
        </Container>

        {/*<ContStartGallery onClick={() => onShowModalGalleryOxean()}>*/}
        <ContStartGallery>
          <ImgStartGallery src={imgGallery01} alt={''} />
        </ContStartGallery>
        {/*{
          showModalGalleryOxean &&
            <ModalGuzbarraf onClose={() => onHideModalGalleryOxean()}>
              <GlobalStyleModal/>
              <Carousel
                isRTL={false}
                itemsToScroll={1}
                itemsToShow={1}
                itemPadding={[0, 0]}
                renderArrow={myArrowGalleryOxean}
                outerSpacing={0}
                itemPosition={consts.START}
                className={'rec-carousel-wrapper-carousel-in-modal'}
              >
                <div id={1}>
                  <img src={imgGallery01} />
                </div>
                <div id={2}>
                  <img src={imgGallery01} />
                </div>
                <div id={3}>
                  <img src={imgGallery01} />
                </div>
                <div id={4}>
                  <img src={imgGallery01} />
                </div>
                <div id={5}>
                  <img src={imgGallery01} />
                </div>
              </Carousel>
            </ModalGuzbarraf>
        }*/}
      </ContGallery>

      <ContAmenities>
        <Container>
          <SectionTitle><span>AMENIDADES</span></SectionTitle>
        </Container>

        <Amenities/>
      </ContAmenities>

      <ContForm>
        <ContactoComp/>
      </ContForm>

    </Container>
  );
};
