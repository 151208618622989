import styled, {createGlobalStyle} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";
import imgBetas from '../../images/oxean/betas.svg';
import imgListStyle from '../../images/list-style-image.svg';
import imgPortada from '../../images/oxean/fachada.jpg';
import imgPortadaMobile from '../../images/oxean/fachada-mobile.jpg';

export const GlobalStyleOxean = createGlobalStyle`
  .oxean{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .back-oxean{
    height: auto;
    min-height: 100%;
    background-color: ${GlobalBackgroundColors.bgSecondary};
  }
  .cont-fig-der{
    display: flex;
    align-items: flex-end;
  }
`
export const ContImgPortada = styled.div`
  width: 100%;
  height: auto;
`
export const ImgPortada = styled.img`
  width: 100%;
  height: auto;
  content:url(${imgPortada});
  
  @media(min-width: 300px) and (max-width: 767px){
    content:url(${imgPortadaMobile});
  }
`
export const ContBetas = styled.div`
  width: 100%;
  min-height: 1000px;
  height: auto;
  background-image: url('${imgBetas}');
  background-repeat: no-repeat;
  background-position: 0px;
  background-size: cover;
`
export const ContImgLogoBlack = styled.div`
  position: relative;
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImgLogoBlack = styled.img`
  width: 100%;
  height: auto;
`
export const ContTextFracc = styled.div`
  position: relative;
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding-top: 25px;
  font: 40px ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorSecondary};
  text-align: center;
`
export const ContImgOxean1 = styled.div`
  width: 100%;
  height: auto;
  margin-top: 25px;
`
export const ImgOxean1 = styled.img`
  width: 100%;
  height: auto;
`
export const ContBloquePreventa = styled.div`
  margin: 0 auto;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 90%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 70%;
  }
`
export const TextoPreventa = styled.div`
  margin-bottom: 15px;
  font: 70px ${GlobalFonts.fontSecondary};
  color: ${GlobalColors.colorLinkHover};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 70px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 70px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 70px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 80px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 90px;
  }
  @media(min-width: 1920px){
    font-size: 100px;
  }
`
export const Cont80Deptos = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Texto80 = styled.div`
  margin-right: 15px;
  font: 80px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 75px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 80px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 80px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 80px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 80px;
  }
  @media(min-width: 1920px){
    font-size: 80px;
  }
`
export const TextoDeptos = styled.div`
  font: 36px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorSecondary};
  line-height: 36px;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 28px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 32px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 34px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 36px;
  }
  @media(min-width: 1920px){
    font-size: 36px;
  }
`
export const ContListDeptos = styled.div`
  font: 20px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorSecondary};
  line-height: 24px;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: auto 30px;
  }
`
export const UlListDeptos = styled.ul`
  list-style-image: url(${imgListStyle});
  font: 20px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorSecondary};
`
export const ContPreventaTextos = styled.div`
  height: 100%;
  margin: 25px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const TextosOxean = styled.div`
  font: 20px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorMenuSecondary};
`
export const ContDetailsDeptos = styled.div`
  margin: 25px 0;
`
export const DetailsDeptosItem = styled.div`
  font: 20px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorMenuSecondary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  
  &>div:first-child {
    width: 100px;
  }
  &>div:nth-child(2) {
    width: 50px;
  }
`
export const ContDetallesCasas = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  background-color: ${GlobalBackgroundColors.bgPrimary};
`
export const ContGallery = styled.div`
  width: 100%;
  padding-top: 35px;
  //padding-bottom: 35px;
  background-color: ${GlobalBackgroundColors.bgSecondary};
`
export const SectionTitle = styled.div`
  font-family: ${GlobalFonts.fontTertiary};
  text-align: center;
  color: ${GlobalColors.colorMenuSecondary};

  & > span{
    color: ${GlobalColors.colorLinkHover};
  }

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 50px;
    line-height: 80px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 60px;
    line-height: 90px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 70px;
    line-height: 110px;
  }
  @media(min-width: 1408px){
    font-size: 70px;
    line-height: 110px;
  }
`
export const ContStartGallery = styled.div`
  width: 100%;
  margin: 45px auto 15px;
  //cursor: pointer;
`
export const ImgStartGallery = styled.img`
  width: 100%;
`
export const ContAmenities = styled.div`
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: ${GlobalBackgroundColors.bgSecondary};
`
export const ContForm = styled.div`
  min-height: 425px;
  
  background-color: ${GlobalBackgroundColors.bgContact};
`
