import React from "react"
import {MyTabs, MyTabList, MyTab, MyTabPanels, MyTabPanel, GlobalStyleTabsCasas} from "./stylesTabsCasas";
import ItemTabsCasaMarea from './itemTabsCasaMarea';
import ItemTabsCasaBrisa from './itemTabsCasaBrisa';
import ItemTabsCasaCoral from './itemTabsCasaCoral';
import ItemTabsCasaParadisa from './itemTabsCasaParadisa';
import ItemTabsCasaTropica from './itemTabsCasaTropica';

const nameDev = 'oxean';

const TabsCasas = () => {
  const fnTabClickOxean = (val) => {
    console.log('fnTabClickOxean => val =>', val);
    const myClass = 'tab-active';
    const elements = document.querySelectorAll(`.tabs-casas-${nameDev}`);

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove(myClass);

      if (i === (val - 1)) {
        elements[i].classList.add('tab-active');
      }
    }

    // document.getElementById(`tab-${nameDev}-${val}`).classList.add('tab-active');
  }

  React.useEffect(() => {
    //window.addEventListener('keydown', handleKeyDown);
    const elements = document.querySelectorAll(`.tabs-casas-${nameDev}`);

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", function() {
        fnTabClickOxean(i + 1);
      });
    }

    // cleanup this component
    return () => {
      //window.removeEventListener('keydown', handleKeyDown);
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", fnTabClickOxean);
      }
    };
  }, []);

  return (
    <MyTabs>
      <GlobalStyleTabsCasas/>
      <MyTabList>
        <MyTab id={`tab-${nameDev}-1`} className={`tabs-casas-${nameDev} tab-active`}>MAREA</MyTab>
        <MyTab id={`tab-${nameDev}-2`} className={`tabs-casas-${nameDev}`}>BRISA</MyTab>
        <MyTab id={`tab-${nameDev}-3`} className={`tabs-casas-${nameDev}`}>CORAL</MyTab>
        <MyTab id={`tab-${nameDev}-4`} className={`tabs-casas-${nameDev}`}>TRÓPICA</MyTab>
        <MyTab id={`tab-${nameDev}-5`} className={`tabs-casas-${nameDev}`}>PARADISA</MyTab>
        {/*<MyTab id={'tab-4'} className={'tabs-casas not-allowed'} disabled>CASA TIERRA<br/>Segunda etapa</MyTab>*/}
      </MyTabList>

      <MyTabPanels>
        <MyTabPanel>
          <ItemTabsCasaMarea/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaBrisa/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaCoral/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaTropica/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaParadisa/>
        </MyTabPanel>
      </MyTabPanels>
    </MyTabs>
  )
}
export default TabsCasas
