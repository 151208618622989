import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {PopupboxContainer, PopupboxManager} from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import {GlobalStylePopupbox} from "../../styles/GlobalStylesPopupbox";
import {
  ContDetalles,
  ContDetallesNumeros,
  ContItemDetalles,
  ContNombreCasa,
  ItemDetalles,
  ItemDetallesIco,
  ContDetallesNumerosTitulo,
  ContDetallesNumerosValor,
  GlobalStylesItemTabsCasas,
  ContImgPlantasDeCasa,
  ImgPlantasDeCasa,
  ContTextoPlantas,
  ContPlantasCasa,
  BtnLightbox, ContBtnsLightbox, ImgPlantas, ContainerItemCasa,
} from './stylesItemTabsCasas';
import check from '../../images/oxean/check.svg';
import imgCasa from '../../images/oxean/casas/casa-paradisa.png';
import imgPlano from '../../images/oxean/casas/casa-paradisa-plano.png';
import imgUbicacion from '../../images/oxean/casas/ubicacion-en-torre-paradisa.svg';
// import fileFachadaPrincipal from '../../images/punta-mar/casa-c-fachada-principal.jpg';
// import fileFachadaPosterior from '../../images/punta-mar/casa-c-fachada-posterior.jpg';
// import fileSala from '../../images/punta-mar/casa-c-sala.jpg';
// import fileRecamara from '../../images/punta-mar/casa-c-recamara-principal.jpg';
// import fileRecamara2 from '../../images/punta-mar/casa-c-recamara-dos.jpg';
// import fileBanio from '../../images/punta-mar/casa-c-banio-principal.jpg';

const nameCasa = 'Paradisa';

const ItemTabsCasaParadisa = ({data}) => {
  const fnShowPlantas = (val) => {
    switch(val) {
      case 0:
        document.getElementById(`contCasa${nameCasa}`).classList.remove('display-no');
        document.getElementById(`contPlano${nameCasa}`).classList.add('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnCasa${nameCasa}`).classList.add('negrita');
        document.getElementById(`btnPlano${nameCasa}`).classList.remove('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.remove('negrita');
        break;
      case 1:
        document.getElementById(`contPlano${nameCasa}`).classList.remove('display-no');
        document.getElementById(`contCasa${nameCasa}`).classList.add('display-no');
        document.getElementById(`contUbicacion${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnPlano${nameCasa}`).classList.add('negrita');
        document.getElementById(`btnCasa${nameCasa}`).classList.remove('negrita');
        document.getElementById(`btnUbicacion${nameCasa}`).classList.remove('negrita');
        break;
      case 2:
        document.getElementById(`contUbicacion${nameCasa}`).classList.remove('display-no');
        document.getElementById(`contCasa${nameCasa}`).classList.add('display-no');
        document.getElementById(`contPlano${nameCasa}`).classList.add('display-no');

        document.getElementById(`btnUbicacion${nameCasa}`).classList.add('negrita');
        document.getElementById(`btnCasa${nameCasa}`).classList.remove('negrita');
        document.getElementById(`btnPlano${nameCasa}`).classList.remove('negrita');
        break;
    }
  }

  const openPopupbox = (title, file, css) => {
    const content = <img className={css} src={file} />;
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: title
        },
        fadeIn: true,
        fadeInSpeed: 500
      }
    })
  }

  return (
    <Container isFluid={true} className={' margin-bottom-plantas'}>
      <GlobalStylePopupbox/>
      <GlobalStylesItemTabsCasas/>
      <Columns className={'columns-margin-side-no'}>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>

          <ContainerItemCasa isFluid={false}>
            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <ContNombreCasa>
                  Paradisa
                </ContNombreCasa>
              </Column>
            </Columns>

            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>3 recámaras</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Cocina</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Sala-comedor</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>3.5 baños</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Área de lavandería</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Doble terraza</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
            </Columns>

            <Columns
              className={'columns-margin-side-no display-flex cont-details-numbers mgt-details-numbers'}
              isMultiline={true}
            >
              <Column className={'global-columns-paddign-no no-display-mobile'} isSize={{mobile: 12, tablet: 6, desktop: 4, widescreen: 4}}>
                &nbsp;
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 4, widescreen: 4}}>
                <ContDetallesNumeros>
                  <ContDetallesNumerosTitulo>
                    TOTAL:
                  </ContDetallesNumerosTitulo>
                  <ContDetallesNumerosValor>
                    226.13 m2
                  </ContDetallesNumerosValor>
                </ContDetallesNumeros>
              </Column>
              <Column className={'global-columns-paddign-no no-display-mobile'} isSize={{mobile: 12, tablet: 6, desktop: 4, widescreen: 4}}>
                &nbsp;
              </Column>
            </Columns>
          </ContainerItemCasa>
        </Column>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>
          <ContPlantasCasa>
            <ContImgPlantasDeCasa id={`contCasa${nameCasa}`}>
              <ImgPlantasDeCasa src={imgCasa} alt={''} />
              {/*<ContBtnsLightbox>
                <BtnLightbox id={'casa-c-fachada-principal'} onClick={() => openPopupbox('Fachada Principal', fileFachadaPrincipal, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-fachada-posterior'} onClick={() => openPopupbox('Fachada Posterior', fileFachadaPosterior, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-sala'} onClick={() => openPopupbox('Sala', fileSala, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>*/}
            </ContImgPlantasDeCasa>

            <ContImgPlantasDeCasa id={`contPlano${nameCasa}`} className={'display-no'}>
              <ImgPlantasDeCasa src={imgPlano} alt={''} />
              {/*<ContBtnsLightbox>
                <BtnLightbox id={'casa-c-recamara'} onClick={() => openPopupbox('Recamara Principal', fileRecamara, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-recamara-2'} onClick={() => openPopupbox('Recamara Dos', fileRecamara2, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-banio'} onClick={() => openPopupbox('Baño Principal', fileBanio, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>*/}
            </ContImgPlantasDeCasa>

            <ContImgPlantasDeCasa id={`contUbicacion${nameCasa}`} className={'display-no'}>
              <ImgPlantasDeCasa src={imgUbicacion} alt={''} />
            </ContImgPlantasDeCasa>

            <ContTextoPlantas>
              <ImgPlantas id={`btnCasa${nameCasa}`} onClick={() => fnShowPlantas(0)} src={imgCasa} />
              <ImgPlantas id={`btnPlano${nameCasa}`} onClick={() => fnShowPlantas(1)} src={imgPlano}/>
              <ImgPlantas id={`btnUbicacion${nameCasa}`} onClick={() => fnShowPlantas(2)} src={imgUbicacion}/>
            </ContTextoPlantas>
          </ContPlantasCasa>
        </Column>

      </Columns>

      <PopupboxContainer />

    </Container>
  )
}

export default ItemTabsCasaParadisa
