import * as React from "react"
import LayoutPages from "../components/Layout/layoutPages";
import SEO from "../components/seo"
import {Oxean} from '../components/Oxean';

const OxeanPage = () => {
  return (
    <LayoutPages>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <Oxean/>

    </LayoutPages>
  )
}

export default OxeanPage
